import { HeadingLg } from '../../shared/styles';

const Settings = () => {
  return (
    <>
      <HeadingLg>Settings</HeadingLg>
    </>
  );
};

export default Settings;
