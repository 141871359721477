import styled from 'styled-components';

export const Table = styled.div`
  display: block;

  @media only screen and (min-width: 570px) {
    width: 600px;
  }
`;

export const KeyValuePairRow = styled.div`
  align-items: center;

  @media only screen and (min-width: 570px) {
    display: inline-flex;
    width: fit-content;
  }
`;
