import { HeadingLg, VerticalSpaceSm } from '../../shared/styles';
import OutcomeList from './OutcomeList';
import type { FileParseOutcome } from '../../model/FileParseOutcome';
import { useEffect, useState } from 'react';
import databaseService from '../../services/database-service';
import { ProjectSelect } from '../project-select/ProjectSelect';

const FileParseOutcomeComponent = () => {
  const [collectionName, setCollectionName] = useState('');
  const [outcomes, setOutcomes] = useState<FileParseOutcome[]>();

  useEffect(() => {
    const getData = async () => {
      if (!collectionName) return;

      const data = await databaseService.getFilesParseOutcome(collectionName);
      setOutcomes(data);
    };
    getData();
  }, [collectionName]);

  return (
    <>
      <HeadingLg>Parse Outcomes</HeadingLg>
      <VerticalSpaceSm />
      <ProjectSelect
        setCollectionName={setCollectionName}
        collectionName={collectionName}
      />
      <VerticalSpaceSm />
      {outcomes && <OutcomeList outcomes={outcomes} />}
    </>
  );
};

export default FileParseOutcomeComponent;
