import { FileParseOutcome } from '@/model/FileParseOutcome';
import { formatDate } from '../../Utils';
import { TableItem } from './styles';

export type OutcomeItemProps = {
  outcome: FileParseOutcome;
};

const OutcomeItem: React.FC<OutcomeItemProps> = (props) => {
  const { _id, filename, file_path, parse_status, neo_writer_stats } =
    props.outcome;

  return (
    <>
      <TableItem>{_id}</TableItem>
      <TableItem>{filename}</TableItem>
      <TableItem>{file_path}</TableItem>
      <TableItem>{parse_status ? 'Success' : 'Fail'}</TableItem>
      <TableItem>
        {neo_writer_stats?.completed_at
          ? formatDate(neo_writer_stats?.completed_at)
          : ''}
      </TableItem>
      <TableItem>{neo_writer_stats?.elapsed.write_ms}</TableItem>
      <TableItem>{neo_writer_stats?.errors}</TableItem>
    </>
  );
};

export { OutcomeItem };
