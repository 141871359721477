import { HeadingLg } from '../../shared/styles';
import { createContext, useState } from 'react';
import { Chart, ChartControls } from '..';

export interface IChartContext {
  markdown: string;
  markdownGenerated: (md: string) => Promise<void>;
}

export const ChartContext = createContext({});

const ChartHome = () => {
  const [markdown, setMarkdown] = useState('');
  const [showChart, setShowChart] = useState(false);

  const markdownGenerated = async (md: string) => {
    setMarkdown(md);
    await loadChart();
  };

  const loadChart = async () => {
    setShowChart(false);
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        setShowChart(true);
        resolve();
      });
    });
  };

  const initContext: IChartContext = {
    markdown,
    markdownGenerated,
  };

  return (
    <>
      <HeadingLg>Schema Graph</HeadingLg>
      <ChartContext.Provider value={initContext}>
        <ChartControls />
        {!!showChart && <Chart />}
      </ChartContext.Provider>
    </>
  );
};

export default ChartHome;
