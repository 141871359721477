import { ResourcesConfig } from '@aws-amplify/core';
const config: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID!,
      userPoolClientId: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID!,
      userPoolId: process.env.REACT_APP_USER_POOLS_ID!,
      allowGuestAccess: false,
      mfa: { status: 'off' as const },
    },
  },
  API: {
    GraphQL: {
      endpoint: `${process.env.REACT_APP_APPSYNC_API_URL}`,
      region: 'eu-west-2',
      defaultAuthMode: 'apiKey',
      apiKey: `${process.env.REACT_APP_APPSYNC_API_KEY}`,
    },
  },
};

export default config;
