import styled from 'styled-components';
import { LightGray } from '../../shared/styles';

export const Container = styled.div`
  width: fit-content;
  min-width: 500px;
  max-width: 800px;
  background-color: ${LightGray};
  padding-bottom: 20px;
  border-radius: 10px;
  white-space: pre-wrap;
  padding: 20px;
`;
