import {
  HeadingLg,
  VerticalSpaceLg,
  VerticalSpaceSm,
} from '../../shared/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ParseTree from '../parse-tree/ParseTree';
import TreeIcon from '@mui/icons-material/AccountTree';
import DataObjectIcon from '@mui/icons-material/DataObject';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import ParseRaw from '../parse-raw/ParseRaw';
import { ControlsContainer } from './view-script-controls';
import databaseService from '../../services/database-service';
import { FileParseOutcome } from '@/model/FileParseOutcome';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { HintPanel } from '../../shared/components';
import { ProjectSelect } from '../project-select/ProjectSelect';

const ViewScript = () => {
  enum ViewType {
    Tree = 'Tree',
    Raw = 'Raw',
  }
  const [view, setView] = useState<ViewType>(ViewType.Tree);
  const [collectionName, setCollectionName] = useState('');
  const [outcomes, setOutcomes] = useState<FileParseOutcome[] | undefined>(
    undefined
  );
  const [selectedFile, setSelectedFile] = useState<string>('');

  const [parsedJson, setParsedJson] = useState<object>({});
  const [raw, setRaw] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (!collectionName) return;

      const outcomeResponse =
        await databaseService.getFilesParseOutcome(collectionName);
      setOutcomes(outcomeResponse);
    })();
  }, [collectionName]);

  useEffect(() => {
    (async () => {
      if (!selectedFile) return;

      const parsedResponse = await databaseService.getParsedScript(
        collectionName,
        selectedFile
      );
      setRaw(parsedResponse.raw);
      setParsedJson(parsedResponse.parsed);
    })();
  }, [selectedFile, collectionName]);

  const viewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: ViewType
  ) => {
    setView(newView);
  };

  const handleFileSelect = (event: { target: { value: string } }) => {
    setSelectedFile(event.target.value);
  };

  return (
    <>
      <HeadingLg>View Script</HeadingLg>
      <VerticalSpaceSm />
      <HintPanel>
        Use this page to view scripts you have uploaded. Choose between a JSON
        tree view of your parsed script,
        <br />
        Or view the raw SQL itself.
      </HintPanel>
      <VerticalSpaceSm />
      <VerticalSpaceSm />
      <ControlsContainer>
        <ProjectSelect
          collectionName={collectionName}
          setCollectionName={setCollectionName}
        />
        <VerticalSpaceSm />
        <FormControl>
          <InputLabel id="filename-select">File</InputLabel>
          <Select
            labelId="filename-select"
            id="outcome-filename-select"
            value={selectedFile ?? undefined}
            label="File"
            onChange={handleFileSelect}
            disabled={!outcomes?.length}
            style={{ width: '300px' }}
          >
            {outcomes &&
              Array.isArray(outcomes) &&
              outcomes?.map((outcome, index) => (
                <MenuItem key={`${outcome._id}-${index}`} value={outcome._id}>
                  {outcome.filename}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </ControlsContainer>
      <VerticalSpaceSm />
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={viewChange}
        aria-label="text alignment"
      >
        <ToggleButton value={ViewType.Tree} aria-label="tree view">
          <TreeIcon />
        </ToggleButton>
        <ToggleButton value={ViewType.Raw} aria-label="raw script">
          <DataObjectIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <VerticalSpaceSm />
      {view === ViewType.Tree ? (
        <ParseTree data={parsedJson} />
      ) : (
        <ParseRaw raw={raw} />
      )}
      <VerticalSpaceLg />
    </>
  );
};

export default ViewScript;
