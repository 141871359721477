import { LightGray } from '../../shared/styles';
import styled from 'styled-components';

export const Ul = styled.ul`
  list-style-type: none;
  padding-inline-start: 0px;
`;

export const Li = styled.li`
  cursor: pointer;

  svg {
    margin-right: 0.5rem;
  }
`;

export const LiDivItem = styled.div<{ depth: number }>`
  padding-left: ${(props) => props.depth * 10}px;
  border-left: 1px dashed #ccc;
`;

export const Container = styled.div`
  width: 100rem;
  height: 50rem;
  min-width: 500px;
  background-color: ${LightGray};
  padding: 15px;
  border-radius: 10px;

  cursor: grab;
  overflow: auto;
  position: absolute;
`;

export const NodeContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;
