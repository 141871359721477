import { HeadingMd, VerticalSpaceSm } from '../../shared/styles';
import { Container } from './parse-raw-styles';

export type ParseRawProps = {
  raw: string;
};

const ParseRaw: React.FC<ParseRawProps> = (props) => {
  const { raw } = props;

  return (
    <>
      <HeadingMd>Raw Script</HeadingMd>
      <VerticalSpaceSm />
      <Container>{raw}</Container>
    </>
  );
};

export default ParseRaw;
