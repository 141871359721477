import styled from 'styled-components';
import { LightBlue } from './styles';

const HintPanel = styled.div`
  background-color: ${LightBlue};
  padding: 20px;
  border-radius: 10px;
  width: fit-content;
  min-width: 300px;
`;

export { HintPanel };
