// todo - fix import aliases!
import FilesService from '../../services/files-service';
import {
  HeadingLg,
  HeadingMd,
  VerticalSpaceLg,
  VerticalSpaceSm,
} from '../../shared/styles';
import { Button, FormControl, TextField } from '@mui/material';
import { ChangeEvent, useRef, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HintPanel } from '../../shared/components';
import { Container, Controls } from './styles';
import KeyValuePairs, { KeyValuePair } from '../key-value-pairs/KeyValuePairs';
import LoadingButton from '@mui/lab/LoadingButton';

const goToGraph = () => {
  window.location.href = '/chart';
};

const LinkMsg = () => (
  <div>
    Graph build successful!
    <Button onClick={goToGraph} variant="outlined">
      View graph
    </Button>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
window.addEventListener('BUILD_FINISHED', (data) => {
  toast(LinkMsg);
  return false;
});

const SchemaUpload = () => {
  const [file, setFile] = useState(undefined as File | undefined);
  const [project, setProject] = useState('');
  const [database, setDatabase] = useState('');
  const [defaultSchema, setDefaultSchema] = useState('dbo');
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [sqlCmdVars, setSqlCmdVars] = useState<KeyValuePair[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);

  const notifySuccess = (msg: string) => toast(msg);
  const notifyFail = () =>
    toast('Error uploading scripts', {
      type: 'error',
    });

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files?.length) return null;
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleFileUpload = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append('File', file!);
    formData.append('Database', database);
    formData.append('Schema', defaultSchema);
    formData.append('FileName', file!.name);
    formData.append('SqlCommandVars', JSON.stringify(sqlCmdVars));
    formData.append('ProjectName', project);
    formData.append('UserId', 'matt');

    try {
      await FilesService.uploadScript(formData);
      notifySuccess('Script archive successfully uploaded');
      setFile(undefined);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error(error);
      notifyFail();
    } finally {
      setUploading(false);
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.value) return;
    setProject(event.target.value);
  };

  const handleDatabaseChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.value) return;
    setDatabase(event.target.value);
  };

  const handleDefaultSchemaChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.value) return;
    setDefaultSchema(event.target.value);
  };

  const handleKeyValuePairsChanged = (KeyValuePairs: KeyValuePair[]) => {
    setSqlCmdVars(KeyValuePairs);
  };

  return (
    <Container>
      <ToastContainer />
      <HeadingLg>New Project</HeadingLg>
      <VerticalSpaceSm />
      <HintPanel>
        Use this page to create a new project.
        <br />
        Start by adding your project details and sending a zip containing the
        SQL scripts you wish to upload.
      </HintPanel>
      <VerticalSpaceSm />
      <VerticalSpaceSm />
      <HeadingMd>Project Details</HeadingMd>
      <VerticalSpaceSm />
      <Controls>
        <FormControl fullWidth>
          <TextField
            label="Project name"
            type="text"
            placeholder="Project Name"
            onChange={handleNameChange}
            style={{ width: '200px' }}
          ></TextField>
        </FormControl>
        <VerticalSpaceSm />
        <FormControl fullWidth>
          <TextField
            label="Database"
            type="text"
            placeholder="Database"
            onChange={handleDatabaseChange}
            style={{ width: '200px' }}
            disabled={!project}
          ></TextField>
        </FormControl>
        <VerticalSpaceSm />
        <FormControl fullWidth>
          <TextField
            label="Schema"
            type="text"
            placeholder="dbo"
            value={defaultSchema}
            onChange={handleDefaultSchemaChange}
            style={{ width: '200px' }}
            disabled={!project || !database}
          ></TextField>
        </FormControl>
      </Controls>
      <VerticalSpaceLg />
      <HeadingMd>SQL Command Variables</HeadingMd>
      <VerticalSpaceSm />
      <KeyValuePairs
        addButtonText="Add variable"
        onKeyValuePairChanged={handleKeyValuePairsChanged}
      />
      <VerticalSpaceLg />
      <HeadingMd>Select a zip to upload</HeadingMd>
      <VerticalSpaceSm />
      <FormControl variant="standard">
        <input
          id="file-chooser"
          type="file"
          onChange={handleFileChange}
          ref={fileInputRef}
          accept=".zip"
        />
      </FormControl>
      <VerticalSpaceLg />
      <LoadingButton
        id="upload-button"
        title="Upload"
        variant="contained"
        disabled={!file || uploading}
        onClick={handleFileUpload}
        loading={uploading}
        loadingPosition="start"
        startIcon={<SaveIcon />}
      >
        Upload Script Archive
      </LoadingButton>
    </Container>
  );
};

export default SchemaUpload;
