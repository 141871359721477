import { getConfigValue } from '../Utils';
import ApiClient from './api-client';

class FilesService {
  private apiClient = new ApiClient(getConfigValue('REACT_APP_FILE_API_URL'));

  uploadScript(request: FormData): Promise<void> {
    return this.apiClient.post('/scripts', request);
  }
}

const service = new FilesService();
export default service;
