import './App.css';
import { ErrorBoundary, Layout, RouteWrapper } from './components';
import {
  WithAuthenticatorProps,
  withAuthenticator,
} from '@aws-amplify/ui-react';

function App({ signOut }: WithAuthenticatorProps) {
  return (
    <div className="App">
      <ErrorBoundary>
        <Layout signOut={signOut!}>
          <RouteWrapper />
        </Layout>
      </ErrorBoundary>
    </div>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
