import { FileParseOutcome } from '@/model/FileParseOutcome';
import { OutcomeItem } from './OutcomeItem';
import { OutcomeListContainer, TableHeader } from './styles';

type OutcomeListProps = {
  outcomes: FileParseOutcome[];
};

const OutcomeList: React.FC<OutcomeListProps> = (props) => {
  const data = props.outcomes;
  const columnCount = 7;

  return (
    <OutcomeListContainer columns={columnCount}>
      <TableHeader>id</TableHeader>
      <TableHeader>filename</TableHeader>
      <TableHeader>file path</TableHeader>
      <TableHeader>parse status</TableHeader>
      <TableHeader>graph written at</TableHeader>
      <TableHeader>write ms</TableHeader>
      <TableHeader>errors</TableHeader>
      {data.map((item) => (
        <OutcomeItem outcome={item} key={item._id}></OutcomeItem>
      ))}
    </OutcomeListContainer>
  );
};

export default OutcomeList;
