import { generateClient } from 'aws-amplify/api';
import * as subscriptions from './graphql/subscriptions';

class GraphqlClient {
  constructor() {
    const client = generateClient();
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const createSub = client
      .graphql({ query: subscriptions.message })
      .subscribe({
        next: ({ data }) => {
          console.log('Notification received! ', data);
          window.dispatchEvent(new Event('BUILD_FINISHED'));
        },
        error: (error) => console.warn(error),
      });
  }
}

export default GraphqlClient;
