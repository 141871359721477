import { Entity } from '@/model/Entity';
import { FileParseOutcome } from './model/FileParseOutcome';
import { ParsedScript } from './model/ParsedScript';

const schemas: Entity[] = [
  {
    id: 1,
    name: 'test 1',
  },
];

const databases: Entity[] = [
  {
    id: 1,
    name: 'test 1',
  },
  {
    id: 2,
    name: 'test 2',
  },
];

const tables: Entity[] = [
  {
    id: 1,
    name: 'test 1',
  },
  {
    id: 2,
    name: 'test 2',
  },
  {
    id: 3,
    name: 'test 3',
  },
];

const markdown = `
    flowchart LR

    classDef NemoDatabase fill:#DDD;font-size:large
    classDef NemoSchema fill:#BBB
    classDef NemoTable fill:#FFF
      style 347 fill:#DDD;font-size:large
      subgraph 347[Database: TransformDiagnostic]
      
        style 367 fill:#BBB
        subgraph 367[Schema: Dimension]
        
          n569[["Calendar"]]:::NemoTable
              end
      end
  `;

const fileParseOutcomes: FileParseOutcome[] = [
  {
    _id: 'id_1',
    filename: 'test1.sql',
    file_path: '',
    parse_status: 1,
    neo_writer_stats: {
      elapsed: { prepare_ms: 12, write_ms: 130 },
      completed_at: '2024-08-09 15:55:19.529577',
    },
  },
  {
    _id: 'id_2',
    filename: 'test2.sql',
    file_path: '',
    parse_status: 0,
  },
];

const parsedScript: ParsedScript = {
  _id: 'id_1',
  filename: 'test1.sql',
  raw: `
    USE [TestDatabase]<br/>
    GO<br/>
    SET ANSI_NULLS ON<br/>
    GO<br/>
    SET QUOTED_IDENTIFIER ON<br/>
    GO<br/>
    CREATE TABLE [dbo].[TestTable](<br/>
      [ID] [int] IDENTITY(1,1) NOT NULL,<br/>
      [UserID] [int] NOT NULL,<br/>
      [Created] [date] NOT NULL,<br/>
      [TypeID] [int] NOT NULL,<br/>
    CONSTRAINT [PK_Test] PRIMARY KEY CLUSTERED <br/>
    (<br/>
      [ID] ASC<br/>
    )WITH (PAD_INDEX = OFF, STATISTICS_NORECOMPUTE = OFF, IGNORE_DUP_KEY = OFF, ALLOW_ROW_LOCKS = ON, ALLOW_PAGE_LOCKS = ON) ON [PRIMARY]<br/>
    ) ON [PRIMARY]<br/>
    GO<br/>
    ALTER TABLE [dbo].[TestTable] ADD  CONSTRAINT [DF_Test_Created]  DEFAULT (getdate()) FOR [Created]<br/>
    GO
  `,
  parsed: {
    type: 'Script',
    statements: [
      {
        type: 'Ddl',
        action: 'CREATE',
        object_defn: {
          type: 'DatabaseDefn',
          name: 'CatalogA',
        },
      },
    ],
  },
};

export {
  schemas,
  databases,
  tables,
  markdown,
  fileParseOutcomes,
  parsedScript,
};
