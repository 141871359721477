import styled from 'styled-components';

export const ControlsTop = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const Control = styled.div`
  width: 300px;
  margin-right: 20px;
`;

export const Container = styled.div`
  margin-top: 20px;
`;
