import styled from 'styled-components';
import { SideNav } from '..';
import { SignOut } from '@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator';

const LayoutContainer = styled.div`
  position: relative;
  margin-top: 100px;
  margin-left: 100px;
`;

interface LayoutProps {
  children: React.ReactNode;
  signOut: SignOut; // TODO - move this somewhere sensible
}

const Layout = (props: LayoutProps) => {
  return (
    <LayoutContainer>
      <SideNav signOut={props.signOut} />
      {props.children}
    </LayoutContainer>
  );
};

export default Layout;
