import styled from 'styled-components';

export const ControlsContainer = styled.div`
  display: flex;
  width: 650px;
  justify-content: space-between;

  @media only screen and (max-width: 750px) {
    width: auto;
    display: block;
  }
`;
