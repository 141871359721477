import styled from 'styled-components';

export const LightBlue = '#C4E2FB';

export const LightGray = '#E1E6ED';

export const WarnRed = '#EE9C9C';

export const HeadingLg = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const HeadingMd = styled.div`
  font-size: 16px;
`;

export const HeadingSm = styled.div`
  font-size: 12px;
`;

export const VerticalSpaceLg = styled.div`
  display: block;
  height: 40px;
`;

export const VerticalSpaceSm = styled.div`
  display: block;
  height: 20px;
`;
