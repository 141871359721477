import React, { useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { KeyValuePairRow, Table } from './styles';
import { VerticalSpaceSm, WarnRed } from '../../shared/styles';

export type KeyValuePair = {
  key: string;
  value: string;
};

export type KeyValuePairProps = {
  addButtonText: string;
  onKeyValuePairChanged: (KeyValuePairs: Array<KeyValuePair>) => void;
};

const KeyValuePairs: React.FC<KeyValuePairProps> = (props) => {
  const { addButtonText, onKeyValuePairChanged } = props;

  const [kvps, setKvps] = useState<Array<KeyValuePair>>([]);

  const addVariableClicked = () => {
    setKvps([...kvps, { key: '', value: '' }]);
  };

  const removeKvp = (index: number) => {
    const updated = kvps.filter((_, i) => i !== index);
    setKvps(updated);
  };

  const handleKeyChange = (index: number, value: string) => {
    const updatedKvps = [...kvps];
    updatedKvps[index].key = value;
    setKvps(updatedKvps);
  };

  const handleValueChange = (index: number, value: string) => {
    const updatedKvps = [...kvps];
    updatedKvps[index].value = value;
    setKvps(updatedKvps);
    onKeyValuePairChanged(updatedKvps);
  };

  return (
    <Table>
      <Button
        id="add-kvp-button"
        title={addButtonText}
        variant="outlined"
        onClick={addVariableClicked}
      >
        {addButtonText}
      </Button>
      <br />
      <VerticalSpaceSm />
      {kvps.map((kvp, index) => (
        <>
          <KeyValuePairRow key={`kvp-row-${index}`}>
            <FormControl fullWidth key={`form-ctrl-name-${index}`}>
              <TextField
                type="text"
                placeholder="Variable Name"
                style={{ width: '200px', marginRight: '15px' }}
                value={kvp.key}
                onChange={(e) => handleKeyChange(index, e.target.value)}
                key={`var-name-input-${index}`}
              />
            </FormControl>
            <VerticalSpaceSm />
            <FormControl fullWidth key={`form-ctrl-value-${index}`}>
              <TextField
                type="text"
                placeholder="Value"
                style={{ width: '200px', marginRight: '15px' }}
                value={kvp.value}
                onChange={(e) => handleValueChange(index, e.target.value)}
                key={`var-value-input-${index}`}
              />
            </FormControl>
            <VerticalSpaceSm />
            <RemoveCircleIcon
              style={{ cursor: 'pointer', color: WarnRed }}
              onClick={() => removeKvp(index)}
              key={`remove-btn-${index}`}
            />
          </KeyValuePairRow>
          <br />
          <VerticalSpaceSm key={`vertical-space-${index}`} />
        </>
      ))}
    </Table>
  );
};

export default KeyValuePairs;
