import styled from 'styled-components';

type OutcomeListContainerProps = {
  columns: number;
};

export const OutcomeListContainer = styled.div<OutcomeListContainerProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-auto-rows: minmax(35px, auto);
  padding: 2px;

  background: repeating-linear-gradient(#efefef 0 35px, transparent 35px 70px);
`;

export const OutcomeItemContainer = styled.div`
  display: flex;
`;

export const TableHeader = styled.div`
  font-weight: bold;
`;

export const TableItem = styled.div`
  line-height: 35px;
`;
