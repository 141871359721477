import {
  schemas,
  databases,
  tables,
  markdown,
  fileParseOutcomes,
  parsedScript,
} from '../mock-data';
import { Entity } from '@/model/Entity';
import { getConfigValue } from '../Utils';
import ApiClient from './api-client';
import { orderBy } from 'lodash';
import { AxiosError } from 'axios';
import { FileParseOutcome } from '@/model/FileParseOutcome';
import { ParsedScript } from '@/model/ParsedScript';
import { Project } from '@/model/Project';

class DatabaseService {
  constructor() {
    this.test = JSON.parse(getConfigValue('REACT_APP_TEST'));
  }

  private readonly test: boolean;

  private apiClient = new ApiClient(getConfigValue('REACT_APP_PARSE_API_URL'));

  async getCatalogs(): Promise<Entity[]> {
    const catalogId = 0;
    const catalogs = this.test
      ? databases
      : await this.apiClient
          .get(`/catalogs/${catalogId}`)
          .catch((e: AxiosError) => console.error(e));
    return orderBy(catalogs, ['id']);
  }

  async getSchemas(catalogId: number): Promise<Entity[]> {
    const schemaList = this.test
      ? schemas
      : await this.apiClient
          .get(`/schemas/${catalogId}`)
          .catch((e: AxiosError) => console.error(e));
    return orderBy(schemaList, ['id']);
  }

  async getTables(schemaId: number): Promise<Entity[]> {
    const tableList = this.test
      ? tables
      : await this.apiClient
          .get(`/tables/${schemaId}`)
          .catch((e: AxiosError) => console.error(e));
    return orderBy(tableList, ['id']);
  }

  async getMarkdown(tableId: number): Promise<string> {
    return this.test
      ? markdown
      : await this.apiClient
          .get(`/lineage/${tableId}`)
          .catch((e: AxiosError) => console.error(e));
  }

  async getFilesParseOutcome(
    collectionName: string
  ): Promise<FileParseOutcome[]> {
    return this.test
      ? fileParseOutcomes
      : await this.apiClient
          .get(`/filesParseOutcome/${collectionName}`)
          .catch((e: AxiosError) => console.error(e));
  }

  async getParsedScript(
    collectionName: string,
    objectId: string
  ): Promise<ParsedScript> {
    return this.test
      ? parsedScript
      : await this.apiClient
          .get(`/parsedScript/${collectionName}/${objectId}`)
          .catch((e: AxiosError) => console.error(e));
  }

  async getProjects(): Promise<Project[]> {
    return this.test
      ? parsedScript
      : await this.apiClient
          .get(`/projects`)
          .catch((e: AxiosError) => console.error(e));
  }
}

const service = new DatabaseService();
export default service;
