/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

/**
 * A wrapper for axios functionality
 */
class ApiClient {
  private readonly baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getJwtAuthToken() {
    const keyPrefix = `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID}`;
    const loginUser = localStorage.getItem(`${keyPrefix}.LastAuthUser`);
    const key = `${keyPrefix}.${loginUser}.idToken`;
    const jwt = localStorage.getItem(key);
    if (!jwt) {
      console.error('Could not find user auth.');
    }
    return jwt || '';
  }

  addJwtAuthHeader() {
    return {
      Authorization: `Bearer ${this.getJwtAuthToken()}`,
    };
  }

  /**
   * Performs a http GET
   * @param name {string} url
   */
  async get(url: string) {
    const response = await axios.get(`${this.baseUrl}${url}`, {
      headers: this.addJwtAuthHeader(),
    });
    return response.data?.body ? JSON.parse(response.data.body) : undefined;
  }

  /**
   * Posts data to a url
   * @param url {string} The url
   * @param data {any} The data to send
   */
  async post(url: string, data: any) {
    const response = await axios.post(`${this.baseUrl}${url}`, data, {
      headers: {
        ...this.addJwtAuthHeader(),
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  }

  /**
   * Put data to a url
   * @param url {string} The url
   * @param data {any} The data to send
   */
  async put(url: string, data: any) {
    const response = await axios.put(`${this.baseUrl}${url}`, data, {
      headers: {
        ...this.addJwtAuthHeader(),
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  }
}

export default ApiClient;
