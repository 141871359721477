/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const message = /* GraphQL */ `subscription Message {
  message
}
` as GeneratedSubscription<
  APITypes.MessageSubscriptionVariables,
  APITypes.MessageSubscription
>;
