enum Theme {
  Base = 'base',
  Forest = 'forest',
  Dark = 'dark',
  Default = 'default',
  Neutral = 'neutral',
}

enum SecurityLevel {
  Strict = 'strict',
  Loose = 'loose',
  Antiscript = 'antiscript',
  Sandbox = 'sandbox',
}

export const chartConfig = {
  theme: Theme,
  securityLevel: SecurityLevel,
};
