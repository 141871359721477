import { HeadingMd, VerticalSpaceSm } from '../../shared/styles';
import { TreeViewProps } from './TreeView';
import TreeView from './TreeView';
import { Container } from './parse-tree-styles';

const ParseTree: React.FC<TreeViewProps> = (props) => {
  const { data } = props;

  let pos = { top: 0, left: 0, x: 0, y: 0 };
  const ele = document.getElementById('tree-view')!;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mouseMoveHandler = function (e: any) {
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  };

  const mouseUpHandler = function () {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    ele.style.cursor = 'grab';
    ele.style.removeProperty('user-select');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mouseDownHandler = function (e: any) {
    pos = {
      // The current scroll
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  return (
    <>
      <HeadingMd>Parsed Script Tree</HeadingMd>
      <VerticalSpaceSm />
      <Container id="tree-view" onMouseDown={mouseDownHandler}>
        <TreeView data={data} />
      </Container>
    </>
  );
};

export default ParseTree;
