import styled from 'styled-components';

export const Container = styled.div`
  width: fit-content;
  display: block;

  @media only screen and (min-width: 760px) {
    width: 800px;
  }
`;

export const Controls = styled.div`
  display: block;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: 760px) {
    display: inline-flex;
    width: 650px;
  }
`;
