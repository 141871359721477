import React, { useState, useEffect } from 'react';
import { LiDivItem, Ul } from './parse-tree-styles';

interface TreeNode {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface TreeViewProps {
  data: TreeNode;
}

const TreeView: React.FC<TreeViewProps> = ({ data }) => {
  const [expandedNodes, setExpandedNodes] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    // Function to recursively set all keys to expanded state
    const setAllKeysExpanded = (obj: TreeNode, parentKey = '') => {
      if (typeof obj === 'object' && obj !== null) {
        Object.keys(obj).forEach((key) => {
          const fullKey = parentKey + key;
          setExpandedNodes((prevState) => ({ ...prevState, [fullKey]: true }));
          setAllKeysExpanded(obj[key], fullKey + '_');
        });
      }
    };
    setAllKeysExpanded(data);
  }, [data]);

  const toggleNode = (key: string) => {
    setExpandedNodes({
      ...expandedNodes,
      [key]: !expandedNodes[key],
    });
  };

  const renderTree = (obj: TreeNode, parentKey = '', depth = 0) => {
    if (typeof obj === 'object' && obj !== null) {
      return (
        <Ul>
          {Object.entries(obj).map(([key, value]) => (
            <li key={parentKey + key}>
              <LiDivItem depth={depth}>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleNode(parentKey + key)}
                >
                  {expandedNodes[parentKey + key] ? '[-]' : '[+]'}
                </span>{' '}
                <strong>{key}:</strong>{' '}
                {expandedNodes[parentKey + key] &&
                  renderTree(value, parentKey + key + '_', depth + 1)}
              </LiDivItem>
            </li>
          ))}
        </Ul>
      );
    } else {
      return <span>{obj}</span>;
    }
  };

  return <div>{renderTree(data)}</div>;
};

export default TreeView;
